const conf = {
    development: {
      config: {
        staging: 'http://127.0.0.1:31080/',
        prod: 'https://zhineng.re-blue.com:32081/',
      },
    },
    production: {
      config: {
        prod: 'https://zhineng.re-blue.com:32081/',
      },
    },
  };

  // 注意⚠️：process.env.NODE_ENV 这个变量不能自定义
  const API_URL = conf[process.env.NODE_ENV].config[process.env.REACT_APP_ENV];
  export default API_URL;

  // 部署的时候需要在生成服务器上设置如下环境变量
  // $vi /etc/profile
  // export REACT_APP_ENV=prod

  // 部署的时候需要在staging服务器上设置如下环境变量
  // $vi /etc/profile
  // export REACT_APP_ENV=staging

  // 部署的时候需要在test服务器上设置如下环境变量
  // $vi /etc/profile
  // export REACT_APP_ENV=test
