const FormatFunc = {
    shuffleArray: function(arr) {
      if (!Array.prototype.shuffle) {
        Array.prototype.shuffle = function () {
          for (var j, x, i = this.length; i; j = parseInt(Math.random() * i), x = this[--i], this[i] = this[j], this[j] = x);
          return this;
        };
      }
      return arr.shuffle();
    },
    formatDuration: function (du) {
      const duStr = du > 59 ? du / 60 : du;
      const duUnit = du > 59 ? '分钟' : '秒';
      return duStr + duUnit;
    },
    formatTime: function(date) {
      const year = date.getFullYear()
      const month = date.getMonth() + 1
      const day = date.getDate()
      const hour = date.getHours()
      const minute = date.getMinutes()
      const second = date.getSeconds()
  
      return [year, month, day].map(this.formatNumber).join('/') + ' ' + [hour, minute, second].map(this.formatNumber).join(':')
    },
    formatDate: function(date) {
      const year = date.getFullYear()
      const month = date.getMonth() + 1
      const day = date.getDate()
      const hour = date.getHours()
      const minute = date.getMinutes()
      const second = date.getSeconds()
  
      return [year, month, day].map(this.formatNumber).join('-')
    },
    birthMonthStart: function() {
      let s = this.birthMonthSelect(-60 * 12);
      return s;
    },
    birthMonthEnd: function() {
      let s = this.birthMonthSelect(-16 * 12);
      return s;
    },
    birthMonthSelect: function(month) {
      const date = new Date();
      const y = date.getFullYear() + month / 12;
      const m = date.getMonth() + month % 12;
      const d = date.getDay();
      return [y, m, d].map(this.formatNumber).join('-');
    },
    dateToTimestamp: function(date) {
      let dt = new Date(date);
      let timestamp = dt.getTime();
      return timestamp / 1000;
    },
    formatNumber: function(n) {
      n = n.toString()
      return n[1] ? n : '0' + n
    },
    timestampToDateMonth: function(timestamp) {
      if (timestamp === '' || timestamp === 'NaN') {
        return '';
      }
      const now = new Date(parseInt(timestamp) * 1000);
      const y = now.getFullYear();
      const m = now.getMonth() + 1;
      const d = now.getDate();
      return `${y}-${m < 10 ? `0${m}` : m}`;
    },
    timestampToWeek: function (timestamp) {
      if (timestamp === '' || timestamp === 'NaN') {
        return '';
      }
      const now = new Date(parseInt(timestamp) * 1000);
      const w = now.getUTCDay();
      let week = '';
      switch (w) {
        case 0:
          week = '星期日';
          break;
        case 1:
          week = '星期一';
        break;
        case 2:
          week = '星期二';
        break;
        case 3:
          week = '星期三';
        break;
        case 4:
          week = '星期四';
        break;
        case 5:
          week = '星期五';
        break;
        case 6:
          week = '星期六';
        break;
      }
      return `${week}`;
    },
    timestampToMonthAndDay: function (timestamp) {
      if (timestamp === '' || timestamp === 'NaN') {
        return '';
      }
      const now = new Date(parseInt(timestamp) * 1000);
      const m = now.getMonth() + 1;
      const d = now.getDate();
      return `${m < 10 ? `0${m}` : m}-${d < 10 ? `0${d}` : d}`;
    },
    timestampToMinuteAndSecond: function (timestamp) {
      if (timestamp === '' || timestamp === 'NaN') {
        return '';
      }
      const now = new Date(parseInt(timestamp) * 1000);
      return `${now.toTimeString().substr(0, 5)}`;
    },
    timestampToDate: function(timestamp) {
      if (timestamp === '' || timestamp === 'NaN') {
        return '';
      }
      const now = new Date(parseInt(timestamp) * 1000);
      const y = now.getFullYear();
      const m = now.getMonth() + 1;
      const d = now.getDate();
      return `${y}年${m < 10 ? `0${m}` : m}月${d < 10 ? `0${d}` : d}日`;
    },
    timestampToDateAndTime: function(timestamp) {
      if (timestamp === '' || timestamp === 'NaN') {
        return '';
      }
      const now = new Date(parseInt(timestamp) * 1000);
      const y = now.getFullYear();
      const m = now.getMonth() + 1;
      const d = now.getDate();
      return `${y}年${m < 10 ? `0${m}` : m}月${d < 10 ? `0${d}` : d}日 ${now.toTimeString().substr(0, 5)}`;
    },
    timestampToDateAndTimeHyphen: function (timestamp) {
      if (timestamp === '' || timestamp === 'NaN') {
        return '';
      }
      const now = new Date(parseInt(timestamp) * 1000);
      const y = now.getFullYear();
      const m = now.getMonth() + 1;
      const d = now.getDate();
      return `${y}-${m < 10 ? `0${m}` : m}-${d < 10 ? `0${d}` : d} ${now.toTimeString().substr(0, 5)}`;
    },
    timestampToDateAndTimeAndSecondHyphen: function (timestamp) {
      if (timestamp === '' || timestamp === 'NaN') {
        return '';
      }
      const now = new Date(parseInt(timestamp) * 1000);
      const y = now.getFullYear();
      const m = now.getMonth() + 1;
      const d = now.getDate();
      return `${y}-${m < 10 ? `0${m}` : m}-${d < 10 ? `0${d}` : d} ${now.toTimeString().substr(0, 8)}`;
    },
    timestampToDateAndWeekAndTime: function (timestamp) {
      if (timestamp === '' || timestamp === 'NaN') {
        return '';
      }
      const now = new Date(parseInt(timestamp) * 1000);
      const y = now.getFullYear();
      const m = now.getMonth() + 1;
      const d = now.getDate();
      const w = now.getUTCDay();
      return `${y}年${m < 10 ? `0${m}` : m}月${d < 10 ? `0${d}` : d}日 ${this.timestampToWeek(timestamp)} ${now.toTimeString().substr(0, 5)}`;
    },
    removeByValue: function(arr, val) {
      for (var i = 0; i < arr.length; i++) {
        if (arr[i].url == val.url) {
          arr.splice(i, 1);
          break;
        }
      }
    },
    formatTimeNumber: function(n) {
      if (n < 10) {
        return '00:0' + n;
      } else if (n < 60) {
        return '00:' + n;
      } else if (n > 59) {
        let min = Math.floor(n / 60);
        let second = (n % 60);
        let minStr = min < 10 ? '0' + min.toString() : min.toString();
        let secondStr = second < 10 ? '0' + second.toString() : second.toString();
        return minStr + ':' + secondStr;
      } else {
        return '00:00';
      }
    },
    deepCloneObject: function(obj) {
      return JSON.parse(JSON.stringify(obj));
    },
    randomSort(a, b) {
      return Math.random() > 0.5 ? -1 : 1;
    }
  }
  
  module.exports = FormatFunc;
  