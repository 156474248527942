import React from 'react';
import {HashRouter,Route,Switch} from 'react-router-dom';
import Daily from './Daily';
import Month from './Month';


const BasicRoute =() => (
    <HashRouter>
      <Switch>
          <Route exact path="/" component={Daily} key="daily"/>
          <Route path="/month" component={Month} key="month"/>

      </Switch>
    </HashRouter>
);

export default BasicRoute;
