import React from 'react';
import Login from './pages/login/login';
import logo from './logo.svg';
import { Chart } from '@antv/g2';
import './Daily.css';
import { getSummary, getStoreList, getTopList, checkMobileAccesstoken } from './api/dashboard';
import FormatFunc from './util/FormatFunc';

import { ReactComponent as ClockIcon } from './assets/img/icons/clock.svg';
import { ReactComponent as TriangleupIcon } from './assets/img/icons/triangleup.svg';
import { ReactComponent as TriangledownIcon } from './assets/img/icons/triangledown.svg';

// function App() {
class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pageType: 0,//0 载入页面；1 登录页面；2数据显示页面
      timeNowStr: '',
      TextArray: [],
      storeList: [],
      topList: [],
      areaList: [],
      provinceList: [],
      zonuleList: [],
    };
  }
  async fetchMobileAccesstoken (mobile, accessToken) {
    const result = await checkMobileAccesstoken(mobile, accessToken);
    console.log("fetchMobileAccesstoken")
    console.log(result)
    if (result.data.code == 0) {
      this.setState({
        pageType: 2,
      })
    } else {
      this.setState({
        pageType: 1,
      })
    }
  }
  async fetchSummary () {
    const summary = await getSummary('null', 'null');
    const data = summary.data.data;
    this.setState({
      TextArray: [
       {
         title: '实销额',
         money: data.total?data.total.toLocaleString():0,
         compare: '同比上月同时段',
         percentage: data.totalCompare,
         percentageStr: (data.totalCompare * 100).toFixed(1),
       }, {
         title: '客单数',
         money: data.orderNum?data.orderNum.toLocaleString():0,
         compare: '同比上月同时段',
         percentage: data.orderNumCompare,
         percentageStr: (data.orderNumCompare * 100).toFixed(1),
       },{
         title: '总件数',
         money: data.pieces?data.pieces.toLocaleString():0,
         compare: '同比上月同时段',
         percentage: data.piecesCompare,
         percentageStr: (data.piecesCompare * 100).toFixed(1),
       }, {
         title: 'IPC',
         money: data.ipc?data.ipc:0,
         compare: '同比上月同时段',
         percentage: data.ipcCompare?data.ipcCompare:0,
         percentageStr: ( (data.ipcCompare?data.ipcCompare:0) * 100).toFixed(1),

       }, {
         title: '客单价',
         money: Math.round(data.avt?data.avt:0).toLocaleString(),
         compare: '同比上月同时段',
         percentage: data.avtCompare,
         percentageStr: (data.avtCompare * 100).toFixed(1),

       }, {
         title: '件单价',
         money: Math.round(data.pvt?data.pvt:0).toLocaleString(),
         compare: '同比上月同时段',
         percentage: data.pvtCompare,
         percentageStr: (data.pvtCompare * 100).toFixed(1),

       },
      //  {
      //    title: '总规模增长',
      //    money: data.totalImp,
      //    compare: '同比上月同时段',
      //    percentage: data.totalImpCompare,
      //    percentageStr: (data.totalImpCompare * 100).toFixed(1),

      //  }, {
      //    title: '同店增长',
      //    money: data.storeImp,
      //    compare: '同比上月同时段',
      //    percentage: data.storeImpCompare,
      //    percentageStr: (data.storeImpCompare * 100).toFixed(1),

      //  }, {
      //    title: '周环比',
      //    money: data.wtm,
      //    compare: '同比上月同时段',
      //    percentage: data.wtmCompare,
      //    percentageStr: (data.wtmCompare * 100).toFixed(1),

      //  },
     ]});
  }
  async fetchStoreList () {
    const storeList = await getStoreList('null', 'null');
    this.setState({
      storeList: storeList.data.data.rows.map(item=>{
        return {
          topName: item.xtwlmc,
          topMoney: Math.round(item.kcssje).toLocaleString(),
        }
      })
    })
  }
  async fetchTopList () {
    const topList = await getTopList('24', 'null', 'null');
    this.setState({

      topList: topList.data.data.rows.map(item=>{
        return {
          topName: item.sxsxmc,
          topMoney: Math.round(item.kcssje).toLocaleString(),
          storeNum: item.dpsl,
          average: Math.round(item.kcssje/(item.dpsl>0?item.dpsl:1)).toLocaleString(),
        }
      })
    })
  }
  async fetchAreaList () {
    const topList = await getTopList('23', 'null', 'null');
    this.setState({
      areaList: topList.data.data.rows.map(item=>{
        return {
          topName: item.sxsxmc,
          topMoney: Math.round(item.kcssje).toLocaleString(),
          storeNum: item.dpsl,
          average: Math.round(item.kcssje/(item.dpsl>0?item.dpsl:1)).toLocaleString(),
        }
      })
    })
  }


  async fetchProvinceList () {
    const topList = await getTopList('09', 'null', 'null');
    this.setState({
      provinceList: topList.data.data.rows.map(item=>{
        return {
          topName: item.sxsxmc,
          topMoney: Math.round(item.kcssje).toLocaleString(),
          storeNum: item.dpsl,
          average: Math.round(item.kcssje/(item.dpsl>0?item.dpsl:1)).toLocaleString(),
        }
      })
    })
  }


  //任亚林复制新增小区排名函数
  async fetchZonuleList () {
    const topList = await getTopList('25', 'null', 'null');
    this.setState({
      zonuleList: topList.data.data.rows.map(item=>{
        return {
          topName: item.sxsxmc,
          topMoney: Math.round(item.kcssje).toLocaleString(),
          storeNum: item.dpsl,
          average: Math.round(item.kcssje/(item.dpsl>0?item.dpsl:1)).toLocaleString(),
        }
      })
    })
  }


  async componentDidMount() {
    document.title="Re-BLUE日实时数据"
    this.timer = setInterval(()=>{
      var timestamp = new Date().getTime()/1000;
      const timeStr = FormatFunc.timestampToDateAndTimeAndSecondHyphen(timestamp);
      this.setState({
        timeNowStr: timeStr,
      })
    }, 1000);

    //检查本地是否有登录凭证
    var storage=window.localStorage;
    var mobile = storage.getItem("mobile");
    var accessToken = storage.getItem("accessToken");
    console.log("accessToken")

    console.log(accessToken)


    if (accessToken) {
      this.fetchMobileAccesstoken(mobile, accessToken);
      this.fetchAll();
    } else {
      this.setState({
        pageType: 1,
      })
    }

  }
  componentWillUnmount() {
    clearInterval(this.timer);
  }
  setSet(obj) {
    const that = this;
    this.setState(obj, ()=>{that.fetchAll()});
  }
  async fetchAll() {
    this.fetchSummary();
    this.fetchStoreList();
    this.fetchTopList();
    this.fetchAreaList();
    this.fetchProvinceList();
    this.fetchZonuleList();
  }
  render() {
    const {pageType} = this.state;
    switch (pageType) {
      case 0:
        return <div>载入中</div>;
      case 1:
        return <Login setSet={this.setSet.bind(this)}/>;
      case 2:
        return this.render1();
      default:
        break;
    }
  }
  render1() {
    return (
      <div className="App">
        <div className="title">全国门店数据大屏</div>
    <div className="sub-title"><ClockIcon className="clock-icon"></ClockIcon>    {this.state.timeNowStr}</div>
        <div className="section">今日数据看版</div>
        <div className="dashboard">
          {this.state.TextArray.map(item=>{return (<div className="data-div">
            <div className="data-title">{item.title}</div>
            <div className="data-money">{item.money}</div>
            <div className="data-compare">
              {item.compare}
              {item.percentage > 0 ? <TriangleupIcon className="triangle-icon" /> : <TriangledownIcon className="triangle-icon" />}
              {/* <TriangleupIcon className={item.percentage > 0 ? "triangleup-icon" : "triangledown-icon"}/> */}
              <span className={item.percentage > 0 ?"data-percentup" : "data-percentdown"}>{item.percentageStr}%</span>
            </div>
          </div>)})}
        </div>
        <div className="percentage">
          <div className="percentage-layout">
            <div className="percentage-offline">100%</div>
            <div className="percentage-name">
              <div className="red-point" />
              <div className="">线下门店</div>
            </div>
          </div>
          <div className="percentage-layout">支付金额</div>
          <div className="percentage-layout">
            <div className="percentage-offline">0%</div>
            <div className="percentage-name">线上门店
            <div className="red-point2"/>
            </div>
          </div>
        </div>
        {/* 门店销售排名 */}
        <div className="section">今日门店成交总金额排名</div>
        <div className="top">
          <div className="top-title">
            <div className="order"></div>
            <div className="top-name">门店名</div>
            <div className="top-money">今日成交金额</div>
          </div>
          {this.state.storeList.map((item, index)=>{return (
            <div className={index%2 == 0 ? "top-ranking" : "top-sub-ranking"}>
              <div className="order">{index+1}</div>
              <div className="top-name">{item.topName}</div>
              <div className="top-money">{item.topMoney}</div>
            </div>
          )})}
        </div>
        {/* 片区销售排名 */}
        <div className="section">今日店群1实销金额排名</div>
        <div className="top">
          <div className="top-title">
            <div className="order"></div>
            <div className="top-name">店群名</div>
            <div className="top-store-num">店铺数量</div>
            <div className="top-money">店均金额</div>
            <div className="top-money">今日成交金额</div>
          </div>
          {this.state.topList.map((item, index)=>{return (
            <div className={index%2 == 0 ? "top-ranking" : "top-sub-ranking"}>
              <div className="order">{index+1}</div>
              <div className="top-name">{item.topName}</div>
              <div className="top-store-num">{item.storeNum}</div>
              <div className="top-money">{item.average}</div>
              <div className="top-money">{item.topMoney}</div>
            </div>
          )})}
        </div>
        {/* 店群销售排名 */}
        <div className="section">今日店群2实销金额</div>
        <div className="top">
          <div className="top-title">
            <div className="order"></div>
            <div className="top-name">店群名</div>
            <div className="top-store-num">店铺数量</div>
            <div className="top-money">店均金额</div>
            <div className="top-money">今日总金额</div>
          </div>
          {this.state.areaList.map((item, index)=>{return (
            <div className={index%2 == 0 ? "top-ranking" : "top-sub-ranking"}>
              <div className="order">{index+1}</div>
              <div className="top-name">{item.topName}</div>
              <div className="top-store-num">{item.storeNum}</div>
              <div className="top-money">{item.average}</div>
              <div className="top-money">{item.topMoney}</div>
            </div>
          )})}
        </div>
        {/* 省份排名*/}
        <div className="section">今日省份排名</div>
        <div className="top">
          <div className="top-title">
            <div className="order"></div>
            <div className="top-name">省份</div>
            <div className="top-store-num">店铺数量</div>
            <div className="top-money">店均金额</div>
            <div className="top-money">今日总金额</div>
          </div>
          {this.state.provinceList.map((item, index)=>{return (
            <div className={index%2 == 0 ? "top-ranking" : "top-sub-ranking"}>
              <div className="order">{index+1}</div>
              <div className="top-name">{item.topName}</div>
              <div className="top-store-num">{item.storeNum}</div>
              <div className="top-money">{item.average}</div>
              <div className="top-money">{item.topMoney}</div>
            </div>
          )})}
        </div>

        {/* 区域店群销售排名 */}
        <div className="section">今日小区实销金额</div>
        <div className="top">
          <div className="top-title">
            <div className="order"></div>
            <div className="top-name">店群名</div>
            <div className="top-store-num">店铺数量</div>
            <div className="top-money">店均金额</div>
            <div className="top-money">今日总金额</div>
          </div>
          {this.state.zonuleList.map((item, index)=>{return (
            <div className={index%2 == 0 ? "top-ranking" : "top-sub-ranking"}>
              <div className="order">{index+1}</div>
              <div className="top-name">{item.topName}</div>
              <div className="top-store-num">{item.storeNum}</div>
              <div className="top-money">{item.average}</div>
              <div className="top-money">{item.topMoney}</div>
            </div>
          )})}
        </div>


        {/* <div className="section">今日成交用户情况</div>
        <div className="user">
          <div className="blank"></div>
          <div className="user-money">900</div>
          <div className="user-percentage">女性</div>
        </div>
        <div className="age">
        </div>
        <div className="section">近30天净成交总额走势图</div> */}
      </div>
    );
  }
}

export default App;
